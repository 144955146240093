import PropTypes from 'prop-types';
import { useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Text } from 'components/globals/TypographyElements';
import { Icon } from 'components/globals';
import {
  Content,
  Calendar,
  Day,
  SignContainer,
  SignText,
  Title,
  FirstSign,
  SecondSign,
  ThirdSign,
  StyledText,
  DaysSwitcher,
} from './style';

const container = {
  hidden: {
    opacity: 0,
  },

  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      ease: 'easeIn',
      staggerChildren: 0.1,
    },
  },
};

const containerSign = {
  hidden: {
    opacity: 0,
  },

  visible: {
    opacity: 1,
    scale: 1,

    transition: {
      ease: 'easeIn',
      staggerChildren: 0.1,
      delayChildren: 0.3,
    },
  },
};

const singleItem = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const calendarDaysWednesdayDelivery = [
  { day: 'Fri', color: 'blue' },
  { day: 'Sat', color: 'transparent' },
  { day: 'Sun', color: 'transparent' },
  { day: 'Mon', color: 'green' },
  { day: 'Tue', color: 'green' },
  { day: 'Wed', color: 'orange' },
  { day: 'Thu', color: 'transparent' },
  { day: 'Fri', color: 'transparent' },
];

// const calendarDaysFridayDelivery = [
//   { day: 'Sun', color: 'transparent' },
//   { day: 'Mon', color: 'blue' },
//   { day: 'Tue', color: 'transparent' },
//   { day: 'Wed', color: 'green' },
//   { day: 'Thu', color: 'green' },
//   { day: 'Fri', color: 'orange' },
//   { day: 'Sat', color: 'transparent' },
//   { day: 'Sun', color: 'transparent' },
// ];

const wednesdayDeliveryLabels = {
  mode: 'wednesday',
  first: 'Order by Friday 4:00 PM',
  second: `We're busy cooking Monday + Tuesday`,
  third: 'Your fresh meals are delivered on Wednesday',
};

// const fridayDeliveryLabels = {
//   mode: 'friday',
//   first: 'Order by Monday',
//   second: `We're busy cooking Wednesday + Thursday`,
//   third: 'Your fresh meals are delivered on Friday',
// };

const CalendarSection = ({ items, primary }) => {
  const [sundaySign, mondaySign, wednesdaySign] = items;
  const [calendarDays, setCalendarDays] = useState(calendarDaysWednesdayDelivery);
  const [labels, setLabels] = useState(wednesdayDeliveryLabels);

  const controls = useAnimation();

  useEffect(() => {
    controls.start('visible');

    return () => controls.stop();
  }, [controls, calendarDays]);

  return (
    <section>
      <Content>
        <Title>{primary.title}</Title>

        <Text>Choose your delivery day</Text>

        <DaysSwitcher bold>
          <StyledText
            isActive={labels.mode === 'wednesday'}
            onClick={async () => {
              await controls.start('hidden');
              setLabels(wednesdayDeliveryLabels);
              setCalendarDays([...calendarDaysWednesdayDelivery]);
            }}
          >
            Wednesday
          </StyledText>
          {/* <span>&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;</span> */}
          {/* <StyledText
            isActive={labels.mode === 'friday'}
            onClick={async () => {
              await controls.start('hidden');
              setLabels(fridayDeliveryLabels);
              setCalendarDays([...calendarDaysFridayDelivery]);
            }}
          >
            Friday
          </StyledText> */}
        </DaysSwitcher>
        <Calendar variants={container} initial="hidden" animate={controls} mode={labels.mode}>
          {calendarDays.map((item, index) => (
            <Day key={index} backgroundColor={item.color} variants={singleItem}>
              <SignText>{item.day}</SignText>
            </Day>
          ))}
        </Calendar>
        <SignContainer variants={containerSign} animate={controls}>
          <FirstSign variants={singleItem}>
            <Icon
              src={sundaySign.calendar_arrow_icon.url}
              size={34}
              sizeLg={100}
              animate={{
                rotate: labels.mode === 'wednesday' ? 0 : -90,
                scaleX: labels.mode === 'wednesday' ? -1 : 1,
                translateX: labels.mode === 'wednesday' ? -35 : 0,
              }}
            />

            <SignText>{labels.first}</SignText>
            <Icon src={sundaySign.calendar_day_icon.url} size={34} sizeLg={80} />
          </FirstSign>

          <SecondSign variants={singleItem}>
            <Icon
              src={mondaySign.calendar_arrow_icon.url}
              size={40}
              sizeLg={100}
              animate={{
                scaleX: labels.mode === 'wednesday' ? -1 : 1,
              }}
            />
            <SignText>{labels.second}</SignText>
            <Icon src={mondaySign.calendar_day_icon.url} size={47} sizeLg={120} />
          </SecondSign>

          <ThirdSign variants={singleItem}>
            <Icon src={wednesdaySign.calendar_arrow_icon.url} size={34} sizeLg={110} />
            <SignText>{labels.third}</SignText>
            <Icon src={wednesdaySign.calendar_day_icon.url} size={57} sizeLg={120} />
          </ThirdSign>
        </SignContainer>
      </Content>
    </section>
  );
};

CalendarSection.propTypes = {
  items: PropTypes.array,
  primary: PropTypes.object,
};

export default CalendarSection;
