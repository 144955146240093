import styled from 'styled-components';
import { Icon } from 'components/globals';
import { motion } from 'framer-motion';
import { H2, Text } from 'components/globals/TypographyElements';
import { device } from 'styles/device';

export const Calendar = styled(motion.div)`
  display: flex;
  margin-top: 20px;

  div + div {
    margin-left: 5px;
  }

  ${(p) =>
    p.mode === 'wednesday' &&
    `
    div:last-child {
      display:none;
    }
  `}

  @media ${device.laptop} {
    margin-top: 32px;

    div + div {
      margin-left: 15px;
    }

    ${(p) =>
      p.mode === 'wednesday' &&
      `
    div:last-child {
      display:flex;
    }
  `}
  }

 
`;

export const Day = styled(motion.div)`
  align-items: center;
  background-color: ${({ backgroundColor, theme }) => theme[backgroundColor]};
  border: ${({ backgroundColor, theme }) => `2px solid ${theme[backgroundColor] || theme.yellow}`};
  border-radius: 12px;
  display: flex;
  height: 46px;
  justify-content: center;
  width: 46px;

  @media ${device.mobileM} {
    width: 56px;
    height: 56px;
  }

  @media ${device.laptop} {
    border: ${({ backgroundColor, theme }) => `8px solid ${theme[backgroundColor] || theme.yellow}`};
    width: 122px;
    height: 122px;
  }
`;

export const SignContainer = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  width: 320px;

  @media ${device.laptop} {
    width: 1082px;
  }
`;

export const SignText = styled(Text)`
  @media ${device.laptop} {
    font-size: 26px;
  }
`;

export const FirstSign = styled(motion.div)`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  width: 68px;

  ${SignText} {
    margin-bottom: '10px';
    text-align: 'end';
  }

  @media ${device.laptop} {
    width: 300px;
    position: relative;

    ${SignText} {
      align-self: center;
      width: 100px;
    }

    ${Icon}:first-child {
      margin-left: 5px;
    }

    ${Icon}:last-child {
      position: absolute;
      bottom: 46px;
      right: 14px;
    }
  }
`;

export const SecondSign = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  width: 120px;

  ${SignText} {
    margin-bottom: 18px;
  }

  @media ${device.laptop} {
    width: 300px;

    ${SignText} {
      text-align: center;
    }

    ${Icon}:last-child {
      align-self: flex-end;
      margin-right: 12px;
    }
  }
`;

export const ThirdSign = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  width: 101px;

  ${SignText} {
    margin-bottom: 20px;
    text-align: 'center';
  }

  ${Icon}:last-child {
    align-self: flex-start;
  }

  @media ${device.laptop} {
    width: 300px;

    ${SignText} {
      text-align: start;
    }

    ${Icon}:first-child {
      align-self: flex-start;
      transform: scale(1, -1) rotate(-103deg);
    }

    ${Icon}:last-child {
      align-self: flex-end;
    }
  }
`;

export const Title = styled(H2)`
  margin-bottom: 8px;
  text-align: center;

  @media ${device.laptop} {
    width: auto;
    margin-bottom: 12px;
  }
`;

export const DaysSwitcher = styled(Text)`
  margin-top: 31px;

  @media ${device.laptop} {
    margin-top: 51px;
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: 59px 0 38px 0;

  @media ${device.laptop} {
    padding: 87px 0 68px 0;
  }
`;

export const StyledText = styled(motion.span).attrs(() => ({
  whileHover: {
    scale: 0.95,
    // borderRadius: '100%',
  },
  whileTap: {
    scale: 0.9,
    // borderRadius: '100%',
  },
}))`
  color: ${({ theme }) => theme.black};
  cursor:pointer;
  padding-bottom: 3px;
  position: relative;
  text-decoration: none;

  &:hover {
    /* color: ${({ theme }) => theme.yellow}; */
  }

  /* @media ${device.tablet} { */
    &::after {
      display: block;
      color: ${({ theme, isActive }) => (isActive ? theme.yellow : theme.black)};
      content: '';
      position: absolute;
      opacity: ${({ isActive }) => (isActive ? 1 : 0)};
      background-color: ${({ theme }) => theme.yellow};
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0px) rotate(-1deg);
      height: 3px;
      width: ${({ isActive }) => (isActive ? '110%' : 0)};
      transition: 0.25s;
    }

    &:hover {
      /* color: ${({ theme }) => theme.black}; */

      &::after {
        opacity: 1;
        width: 120%;
      }
    }
  /* } */
`;
