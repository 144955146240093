import PropTypes from 'prop-types';
import useWindowWidth from 'hooks/useWindowSize';
import { useIntersection } from 'components/animationHelpers/IntersectionObserver';
import { GridImage, GridImages } from './style';

const container = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const GridGallery = ({ items }) => {
  const { inView } = useIntersection();

  // hooks for detect window resize;
  const width = useWindowWidth();

  // size for desktop;
  const bigGrid = 331;

  // size for mobile;
  const smallGrid = 163;

  // count of rows according to resolution
  const rows = width > 1024 ? 2 : 3;

  // count for images per row
  const count = Math.ceil(width / (width > 1024 ? bigGrid : smallGrid));

  // get url from prismic
  const urls = items.map((image) => image.background_image.url);

  // duplicate images if count from prismic will not be enough
  const repeatCount = Math.round((count * rows) / items.length) + 1;

  // render array
  const images = Array.from({ length: repeatCount })
    .fill(urls)
    .reduce((acc, val) => acc.concat(val), []);

  // trim unnecessary items;
  images.length = count * rows || 0;

  return (
    <>
      {count > 0 && (
        <GridImages columns={count} variants={container} initial="hidden" animate={inView ? 'visible' : 'hidden'}>
          {images.map((url, index) => (
            <GridImage key={index} variants={item} imageUrl={url} />
          ))}
        </GridImages>
      )}
    </>
  );
};

GridGallery.propTypes = {
  items: PropTypes.array,
};

export default GridGallery;
