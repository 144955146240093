import Link from 'next/link';
import PropTypes from 'prop-types';
// components
import { Container, Icon } from 'components/globals';
import { H2, Text } from 'components/globals/TypographyElements';
import { ButtonLink } from 'components/Buttons/ButtonLink';
// hooks
import useTransferQueryParams from 'hooks/useTransferQueryParams';
// styles
import { theme } from 'styles/theme';
import { Content, CirclesWrapper, Circle, Item } from './style';

const HowDoesItWorkSection = ({ items, primary }) => {
  const queryForTransfer = useTransferQueryParams();

  const [firstItem, secondItem, thirdItem] = items;

  return (
    <section>
      <Container>
        <Content>
          <H2>{primary.title}</H2>
          <CirclesWrapper>
            <Item>
              <Circle color={theme.pink}>
                <Icon src={firstItem.icon.url} size={70} sizeLg={137} alt="" />
              </Circle>
              {firstItem.item_description.map((content, index) => (
                <Text key={index}>{content.text}</Text>
              ))}
            </Item>

            <Item>
              <Circle padding="0 25px 0 0" color={theme.blue}>
                <Icon src={secondItem.icon.url} size={70} sizeLg={137} alt="" />
              </Circle>
              {secondItem.item_description.map((content, index) => (
                <Text key={index}>{content.text}</Text>
              ))}
            </Item>

            <Item>
              <Circle padding="5px 16px 0 0" color={theme.orange}>
                <Icon src={thirdItem.icon.url} size={70} sizeLg={137} alt="" />
              </Circle>
              {thirdItem.item_description.map((content, index) => (
                <Text key={index}>{content.text}</Text>
              ))}
            </Item>
          </CirclesWrapper>
          <Link scroll={false} href={{ pathname: '/checkout/2', query: queryForTransfer }}>
            <ButtonLink>{primary.button}</ButtonLink>
          </Link>
        </Content>
      </Container>
    </section>
  );
};

HowDoesItWorkSection.propTypes = {
  items: PropTypes.array,
  primary: PropTypes.object,
};

export default HowDoesItWorkSection;
