import PropTypes from 'prop-types';
import Accordion from 'components/Accordion';
import { Content, Title, AccordionWrapper } from './style';

const CommonQuestions = ({ items, primary, title }) => {
  return (
    <section>
      <Content className="common-questions" background={primary.common_questions_background.url}>
        <Title>{title || primary.title}</Title>
        <AccordionWrapper>
          {items.map((item, index) => (
            <Accordion key={index} {...item} />
          ))}
        </AccordionWrapper>
      </Content>
    </section>
  );
};

CommonQuestions.propTypes = {
  items: PropTypes.array,
  primary: PropTypes.object,
  title: PropTypes.string,
};

export default CommonQuestions;
