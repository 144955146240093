export const theme = {
  yellow: '#FFB432',
  lightYellow: '#FFF0D6',
  lightYellowSecond: '#FFF1D8',
  orange: '#FF821E',
  blue: '#8CBEC8',
  lightBlue: '#88B7C1',
  pink: '#FF828C',
  green: '#C8C83C',
  darkGreen: '#6BB672',
  lightGreen: '#8ABEC9',
  lightGreenHovered: '#C5DFE4',
  black: '#1E1E1E',
  white: '#FFFFFF',

  darkGrey: '#595C6F',
  darkGreySecond: '#979797',
  grey: '#CCCCCC',
  greySecond: '#DEDEDE',
  lightGrey: '#F5F5F5',
  lightGreySecond: '#F0F0F0',
  sand: '#FFF0d5',
  lightRed: '#FFE9E9',
  red: '#C54D4C',
  lightTurquoise: '#E5F4F8',
  turquoise: '#8CBEC8',
};
