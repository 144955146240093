import styled from 'styled-components';
import { Icon } from 'components/globals';
import { device } from 'styles/device';
import { Text, H3 } from 'components/globals/TypographyElements';

export const AccordionButton = styled.button`
  position: relative;
  background-color: white;
  color: #444;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;

  @media ${device.laptop} {
    padding: 33px 78px;
  }
`;

export const AccordionSection = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

export const AccordionTitle = styled(H3)`
  text-align: left;
`;

export const AccordionContent = styled.div`
  max-height: ${({ maxHeight }) => maxHeight};
  overflow: hidden;
  transition: max-height 0.6s ease;
`;

export const AccordionText = styled(Text)`
  font-family: 'paralucent-light', sans-serif;
  padding: 0 38px 18px 18px;

  @media ${device.laptop} {
    padding: 0 178px 42px 78px;
  }
`;

export const AccordionIcon = styled(Icon)`
  transform: ${({ isActive }) => isActive && `scale(1, -1)`};
  transition: transform 0.6s ease;
  align-self: start;
`;
