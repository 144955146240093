import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledUnderLine } from 'components/globals';
import { useIntersection } from './IntersectionObserver';

const Content = styled.div`
  display: inline-block;
  position: relative;
  width: fit-content;
`;

const UnderlineBox = ({
  children,
  delayOrder, // order of appearance,
  ...rest
}) => {
  const { inView } = useIntersection();

  const offset = 0.7;

  const variants = {
    hidden: { opacity: 0, width: 0 },
    show: {
      opacity: 1,
      width: '100%',
      transition: {
        duration: 0.8,
        delay: delayOrder * offset,
        ease: 'easeIn',
      },
    },
  };

  return (
    <Content>
      {children}
      <StyledUnderLine initial="hidden" animate={inView ? 'show' : 'hidden'} variants={variants} {...rest} />
    </Content>
  );
};

UnderlineBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.string]),
  duration: PropTypes.number,
  delayOrder: PropTypes.number,
};

export default UnderlineBox;
