import styled from 'styled-components';
import { device } from 'styles/device';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 47px 35px 53px 35px;

  @media ${device.laptop} {
    padding: 59px 40px 82px 40px;
  }
`;

export const Title = styled.div`
  text-align: center;
  font-size: 24px;
  margin-bottom: 25px;

  @media ${device.laptop} {
    margin-bottom: 44px;
    font-size: 44px;
  }
`;
