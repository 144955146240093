import React from 'react';
import { setCookie } from 'nookies';

// temporary list of coupons;
// const coupons = ['FB20P'];

export default function withCookies(Component) {
  return class Cookie extends React.Component {
    static async getInitialProps(ctx) {
      if (ctx.query.coupon || ctx.query.afmc) {
        const value = ctx.query.coupon || ctx.query.afmc;

        const couponForApply = `${value.toUpperCase()}`;
        // Set cookie for 7 days
        setCookie(ctx, 'coupon', couponForApply, {
          maxAge: 7 * 24 * 60 * 60,
          path: '/',
        });
      } else {
        setCookie(ctx, 'coupon', 'SAVE30', {
          maxAge: 7 * 24 * 60 * 60,
          path: '/',
        });
      }

      // Check if Page has a `getInitialProps`; if so, call it.
      const pageProps = Component.getInitialProps && (await Component.getInitialProps(ctx));
      // Return props.
      return { ...pageProps };
    }

    render() {
      return <Component {...this.props} />;
    }
  };
}
