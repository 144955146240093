import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import UnderlineBox from 'components/animationHelpers/UnderlineBox';
import { IntersectionObserver } from 'components/animationHelpers/IntersectionObserver';
import { Container } from 'components/globals';
import { ButtonLink } from 'components/Buttons/ButtonLink';
import { Content, Title } from './style';

const FinalSection = ({ primary }) => {
  const { push, pathname } = useRouter();

  const handleClick = () => {
    if (pathname === '/plans') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      push('/checkout/2');
    }
  };

  return (
    <section>
      <IntersectionObserver>
        <Container>
          <Content>
            <Title>
              Healthy eating made&nbsp;
              <UnderlineBox>simple</UnderlineBox>
            </Title>
            {/* <Link
              // scroll={false}
              href="/plans"
              passHref
            > */}
            <ButtonLink onClick={handleClick}>{primary.button}</ButtonLink>
            {/* </Link> */}
          </Content>
        </Container>
      </IntersectionObserver>
    </section>
  );
};

FinalSection.propTypes = {
  primary: PropTypes.object,
};

export default FinalSection;
