import { useEffect } from 'react';

const useScrollTo = (x = 0, y = 0) => {
  useEffect(() => {
    window.scrollTo(x, y);
    window.history.scrollRestoration = 'manual';
  }, [x, y]);
};

export default useScrollTo;
