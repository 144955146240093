import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import arrow from 'assets/icons/arrow.svg';
import {
  AccordionSection,
  AccordionButton,
  AccordionTitle,
  AccordionText,
  AccordionContent,
  AccordionIcon,
} from './style';

const Accordion = ({ title, text }) => {
  const [isActive, setIsActive] = useState(false);

  const content = useRef(null);

  const toggleAccordion = () => setIsActive((prevState) => !prevState);

  return (
    <AccordionSection>
      <AccordionButton onClick={toggleAccordion}>
        <AccordionTitle>{title}</AccordionTitle>
        <AccordionIcon isActive={isActive} src={arrow} size={15} sizeLg={30} />
      </AccordionButton>
      <AccordionContent ref={content} maxHeight={isActive ? `${content.current.scrollHeight}px` : '0px'}>
        <AccordionText>{text}</AccordionText>
      </AccordionContent>
    </AccordionSection>
  );
};

Accordion.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default Accordion;
