import styled from 'styled-components';
import Image from 'components/Image';
import { device } from 'styles/device';

export const Content = styled.div`
  max-width: 320px;
  padding: 66px 20px 35px 20px;
  margin: 0 auto;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.laptop} {
    max-width: inherit;
    padding: 89px 20px 35px 20px;
  }
`;

export const Background = styled(Image)`
  display: block;
  position: absolute;
  left: -201px;
  top: 2px;
  width: 391px;

  @media ${device.laptop} {
    display: none;
  }
`;

export const Circle = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto 12px auto;
  background-color: ${({ color }) => color || 'black'};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding || '0'};

  @media ${device.laptop} {
    width: 135px;
    height: 135px;
    margin: 0 auto 28px auto;
  }
`;

export const CirclesWrapper = styled.div`
  margin: 26px 0 34px 0;
  display: flex;
  flex-direction: column;
  text-align: center;

  div + div {
    margin-top: 25px;
  }

  @media ${device.laptop} {
    flex-direction: row;

    margin: 36px 0 38px 0;

    div + div {
      margin-top: 0;
      margin-left: 80px;
    }
  }
`;

export const Item = styled.div`
  min-width: 155px;

  @media ${device.laptop} {
    min-width: 200px;
  }
`;
