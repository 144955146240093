import styled from 'styled-components';
import { device } from 'styles/device';
import { H2 } from 'components/globals/TypographyElements';

export const AccordionWrapper = styled.div`
  margin: 0 auto;
  max-width: 939px;
`;

export const Content = styled.div`
  background: url(${({ background }) => background});
  background-size: cover;

  padding: 26px 25px 53px 25px;
  position: relative;
  // temporary disabled;
  /* background-attachment: fixed; */

  /* solution for IOS */
  @supports (-webkit-overflow-scrolling: touch) {
    background-size: auto;
    background-attachment: scroll;
  }

  @media ${device.laptop} {
    padding: 59px 40px 67px 40px;
  }
`;

export const Background = styled.img`
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
`;

export const Title = styled(H2)`
  margin-bottom: 25px;
  text-align: center;

  @media ${device.laptop} {
    margin-bottom: 53px;
  }
`;
