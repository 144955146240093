import styled from 'styled-components';
import { motion } from 'framer-motion';
import { device } from 'styles/device';

export const GridImages = styled(motion.ul)`
  position: absolute;
  z-index: -1;
  width: 100%;
  display: grid;
  justify-content: center;
  overflow: hidden;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 163px)`};
  grid-template-rows: repeat(3, 138px);

  @media ${device.laptop} {
    grid-template-columns: ${({ columns }) => `repeat(${columns}, 331px)`};
    grid-template-rows: repeat(2, 319px);
  }
`;
export const GridImage = styled(motion.li)`
  display: block;
  background: ${({ imageUrl }) => `url(${imageUrl})`};
  background-position: center;
  background-size: cover;
`;
